import { useCallback, useEffect, useState } from 'react'
import './App.css'
import { gapi, loadAuth2 } from 'gapi-script'
import useToggl from './hooks/useToggl'
import useSheets from './hooks/useSheets'
import User from './components/user'

function App() {
	const { allToggl, togglLoaded, percentLoaded, isTogglLoaded } = useToggl()
	const { allSheets, sheetsLoaded } = useSheets()
	const [togglLoading, setTogglLoading] = useState(true)
	const [toBeAdded, setToBeAdded] = useState([])
	const [mightNeedUpdate, setMightNeedUpdate] = useState([])
	const [toBeUpdated, setToBeUpdated] = useState([])
	const [runningUpdate, setRunningUpdate] = useState(false)
	const [hasNewEntries, setHasNewEntries] = useState(false)
	const [hasUpdates, setHasUpdates] = useState(false)
	const valueInputOption = 'USER_ENTERED'

	useEffect(() => {
		const start = () => {
			gapi.client.init({
				apiKey: process.env.REACT_APP_API_KEY,
				clientId: process.env.REACT_APP_CLIENT_ID,
				scope: process.env.REACT_APP_SCOPES,
			})
		}
		gapi.load('client:auth2', start)
		console.log(gapi)
	}, [])

	function update() {
		if (hasUpdates) {
			toBeUpdated
				.sort((a, b) => {
					return a.start < b.start ? -1 : a.start > b.start ? 1 : 0
				})
				.forEach((entry, index) => {
					setTimeout(() => {
						sendTogglToSheets(entry, entry.row - 1)
						console.log(`Updating ${entry} in row ${entry.row}`)
					}, index * 2000)
				})
			setHasUpdates(false)
		}
		if (hasNewEntries) {
			const newRow = allSheets.length
			toBeAdded
				.sort((a, b) => {
					return a.start < b.start ? -1 : a.start > b.start ? 1 : 0
				})
				.forEach((entry, index) => {
					let newIndex = newRow + index
					setTimeout(() => {
						sendTogglToSheets(entry, newIndex)
						console.log(`Adding ${entry} in row ${newIndex}`)
					}, index * 2000)
				})
			setHasNewEntries(false)
		}
	}

	const checkForNew = useCallback(
		(sheetsData, togglData) => {
			if (togglLoaded && sheetsLoaded) {
				togglData.forEach(entry => {
					// check each Toggl entry to see if it's already in Sheets
					const isFound = sheetsData.some(row => {
						if (row.id === entry.id) {
							return true
						}
						return false
					})
					if (!isFound) {
						// if the ID isn't found, add it to a State array To Be Added (to Sheets)
						setToBeAdded(toBeAdded => [...toBeAdded, entry])
						setHasNewEntries(true)
						console.log(entry.id, ' needs to be added.')
					} else {
						setMightNeedUpdate(mightNeedUpdate => [...mightNeedUpdate, entry])
					}
				})
				isTogglLoaded(false)
				setRunningUpdate(true)
			}

			if (runningUpdate) {
				sheetsData.forEach(row => {
					const needsUpdate = mightNeedUpdate.some(entry => {
						if (row.updated === entry.updated) {
							return true
						}
						return false
					})
					if (!needsUpdate) {
						setToBeUpdated(toBeUpdated => [...toBeUpdated, row])
						console.log(row.id, ' needs to be updated.')
						setHasUpdates(true)
					}
				})
				setRunningUpdate(false)
			}
		},
		[togglLoaded, sheetsLoaded, mightNeedUpdate, isTogglLoaded, runningUpdate]
	)

	const checkForNewAndUpdated = useCallback(() => {
		checkForNew(allSheets, allToggl)
	}, [allSheets, allToggl, checkForNew])

	useEffect(() => {
		if (togglLoaded && sheetsLoaded) {
			console.log('Toggl is loaded: ', allToggl)
			setTogglLoading(false)
			checkForNewAndUpdated()
		}
	}, [togglLoaded, allToggl, sheetsLoaded, checkForNewAndUpdated])

	useEffect(() => {
		allSheets.length && console.log(allSheets)
		toBeAdded.length && console.log(toBeAdded)
	}, [allSheets, toBeAdded])

	function syncAll() {
		togglLoaded && console.log('all toggl: ', allToggl)
		allToggl
			.sort((a, b) => {
				return a.start < b.start ? -1 : a.start > b.start ? 1 : 0
			})
			.forEach((entry, index) => {
				setTimeout(() => {
					sendTogglToSheets(entry, index)
					console.log('Sending entry ', index + 1, 'of ', allToggl.length)
				}, index * 2000)
			})
	}

	async function sendTogglToSheets(entry, index) {
		let auth2 = await loadAuth2(
			gapi,
			process.env.REACT_APP_CLIENT_ID,
			process.env.REACT_APP_SCOPES
		)
		const currUser = auth2.currentUser.get()
		const authUser = currUser.getAuthResponse()
		const toke = authUser.access_token
		const row = String(index + 1)
		fetch(
			`https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SPREADSHEET_ID}/values/Toggl_Data_2023!${row}:${row}?valueInputOption=${valueInputOption}`,
			{
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${toke}`,
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				body: JSON.stringify({
					values: [
						[entry.start],
						[entry.project],
						[entry.client],
						[entry.user],
						[entry.dur],
						[String(entry.tags)],
						[entry.id],
						[entry.updated],
						[row],
					],
					majorDimension: 'COLUMNS',
				}),
			}
		).then(res => console.log(res))
	}

	return (
		<>
			<div className='main centered'>
				<div className=' user'>
					<User />
				</div>
				<div className='two-col'>
					<div className='section'>
						<h2>Toggle</h2>
						{togglLoading
							? `Loading data from Toggl: ${Math.round(percentLoaded)}%`
							: `${allToggl.length} entries loaded.`}
					</div>
					<div className='section'>
						<h2>Sheets</h2>
						{allSheets.length > 0
							? `${allSheets.length} entries loaded.`
							: 'Loading data from Sheets'}
						{hasNewEntries && !hasUpdates ? (
							<p>{toBeAdded.length} new entries</p>
						) : null}
						{hasNewEntries && hasUpdates ? (
							<p>
								{toBeAdded.length} new entries and {toBeUpdated.length} updates
							</p>
						) : null}
						<button onClick={() => update()}>Add and update?</button>
					</div>
				</div>
			</div>
			<div className='centered'>
				<h2>Nuclear</h2>
				<button onClick={() => syncAll()}>Sync All</button>
			</div>
		</>
	)
}

export default App
