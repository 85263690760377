import { GoogleLogin } from 'react-google-login'
import { GoogleLogout } from 'react-google-login'
import { useState } from 'react'

const clientID =
	'601847530595-eibcjjfasbmdq3ugmrm0q6vekqc6smtl.apps.googleusercontent.com'

function User() {
	const [user, setUser] = useState({})
	const [loggedIn, setLoggedIn] = useState(false)

	const onSuccess = res => {
		console.log('Login sucessful! Current User: ', res.profileObj)
		setLoggedIn(true)
		setUser(res.profileObj)
	}
	const onFailure = res => {
		console.log('Login failed! res: ', res)
	}

	const onLogoutSuccess = () => {
		console.log('Log out successful')
		setLoggedIn(false)
	}

	return (
		<>
			{loggedIn ? (
				<div className='login'>
					<h1>Welcome {user.givenName}</h1>
					<div id='signOutButton'>
						<GoogleLogout
							render={renderProps => (
								<button
									className='logout-btn'
									onClick={renderProps.onClick}
									disabled={renderProps.disabled}>
									Logout
								</button>
							)}
							clientId={clientID}
							buttonText='Logout'
							onLogoutSuccess={onLogoutSuccess}
						/>
					</div>
				</div>
			) : (
				<div className='login'>
					<h1>Please </h1>
					<GoogleLogin
						render={renderProps => (
							<button
								className='login-btn'
								onClick={renderProps.onClick}
								disabled={renderProps.disabled}>
								login
							</button>
						)}
						clientId={clientID}
						onSuccess={onSuccess}
						onFailure={onFailure}
						cookiePolicy={'single_host_origin'}
						isSignedIn={true}
					/>
				</div>
			)}
		</>
	)
}

export default User
