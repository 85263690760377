import { useEffect, useState } from 'react'
import { gapi, loadAuth2 } from 'gapi-script'

function useSheets() {
	const [allSheets, setAllSheets] = useState([])
	const [sheetsLoaded, setSheetsLoaded] = useState(false)

	useEffect(() => {
		const fetchSheets = async () => {
			let allSheets = []
			let auth2 = await loadAuth2(
				gapi,
				process.env.REACT_APP_CLIENT_ID,
				process.env.REACT_APP_SCOPES
			)
			const currUser = auth2.currentUser.get()
			const authUser = currUser.getAuthResponse()
			const toke = authUser.access_token
			const response = await fetch(
				`https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SPREADSHEET_ID}?includeGridData=true&ranges=Toggl_Data_2023&fields=sheets%2Fdata`,
				{
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${toke}`,
						'Content-Type': 'application/json',
						'Accept': 'application/json',
					},
				}
			)
				.then(res => res.json())
				.catch(error => console.log(error))
			const data = await response
			data.sheets[0].data[0].rowData.forEach((e, index) => {
				allSheets = [
					...allSheets,
					{
						start: e.values[0].formattedValue,
						project: e.values[1].formattedValue,
						client: e.values[2].formattedValue,
						user: e.values[3].formattedValue,
						dur: e.values[4].formattedValue,
						tags: e.values[5].formattedValue,
						id: Number(e.values[6].formattedValue),
						updated: e.values[7].formattedValue,
						row: Number(e.values[8].formattedValue),
					},
				]
				if (index + 1 === data.sheets[0].data[0].rowData.length) {
					//console.log('Sheets loaded')
				}
			})
			setSheetsLoaded(true)
			setAllSheets(allSheets)
		}
		fetchSheets()
	}, [])
	return { allSheets, sheetsLoaded }
}
export default useSheets
