import { useEffect, useState } from 'react'
import getCurrentDate from '../components/getCurrentDate'

function convertMsToHM(milliseconds) {
	let seconds = milliseconds / 1000
	let minutes = seconds / 60
	let hours = minutes / 60
	//seconds = seconds % 60 minutes = minutes % 60 hours = hours % 24
	return `${Math.round(hours * 100) / 100}`
}

function getLoaded(current, total) {
	return (current * 100) / total
}

function useToggl() {
	const [allToggl, setAllToggl] = useState([])
	const [togglLoaded, isTogglLoaded] = useState(false)
	const [percentLoaded, setPercentLoaded] = useState(null)

	useEffect(() => {
		// Initial Toggl API Call
		const fetchToggl = async () => {
			let morePages = true
			let currentPage = 0
			const currentDate = getCurrentDate()
			while (morePages) {
				currentPage++
				const response = await fetch(
					`https://api.track.toggl.com/reports/api/v2/details?since=2023-01-01&user_agent=api_test&workspace_id=5002769&until=${currentDate}&page=${currentPage}`,
					{
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
							'Authorization':
								'Basic ZTFjZTdmN2EyNWIwOGUzZGY4OWUzNjEwMjdiZTkzNDI6YXBpX3Rva2Vu',
						},
					}
				).catch(error => console.log(error))
				const data = await response.json()
				let totalPages = Math.ceil(data.total_count / data.per_page)
				setPercentLoaded(getLoaded(currentPage, totalPages))

				data.data.forEach(e =>
					setAllToggl(allToggl => [
						...allToggl,
						{
							start: e.start,
							client: e.client,
							description: e.description,
							dur: convertMsToHM(e.dur),
							id: e.id,
							is_billable: e.is_billable,
							project: e.project,
							project_hex_color: e.project_hex_color,
							tags: e.tags,
							updated: e.updated,
							user: e.user,
						},
					])
				)
				await new Promise(resolve => setTimeout(resolve, 100))
				morePages = currentPage < totalPages
			}
			isTogglLoaded(true)
		}
		fetchToggl()
	}, [])
	return { allToggl, togglLoaded, percentLoaded, isTogglLoaded }
}

export default useToggl
